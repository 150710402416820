import { Controller } from "stimulus"

import CodeMirror from "codemirror"
import "codemirror/mode/ruby/ruby"
import "codemirror/theme/material"

// this is imported in app/javascript/packs/application.js via a/j/src/application.scss instead:
// import "codemirror/lib/codemirror.css"

export default class extends Controller {
  static targets = [ "form", "codemirror", "saveButton" ]

  saveButtonClass = "btn-primary";

  connect() {
    this.setSaveButtonClass("btn-secondary");

    let codemirror = CodeMirror.fromTextArea(this.codemirrorTarget, {
      lineNumbers: true,
      mode: "ruby",
      theme: "material",
    });

    codemirror.on("change", () => {
      this.setSaveButtonClass("btn-primary");
    });
  }

  disconnect() {
    this.formTarget.removeEventListener(this.ajaxSuccessHandler);
    this.formTarget.removeEventListener(this.ajaxErrorHandler);
  }

  ajaxBefore() {
    this.setSaveButtonMode("loading");
  }

  ajaxSuccess(ev) {
    let [_doc, _status, xhr] = ev.detail;

    let response = JSON.parse(xhr.responseText);

    if (response.created) {
      location.href = response.created.url;
      return;
    }

    this.setSaveButtonMode("active");

    if (response.saved) {
      this.setSaveButtonClass("btn-success");

      setTimeout(() => {
        if (this.saveButtonClass === "btn-success") {
          this.setSaveButtonClass("btn-secondary");
        }
      }, 500);
    }
  }

  ajaxError() {
    this.setSaveButtonMode("active");
  }

  setSaveButtonClass(className) {
    let classList = this.saveButtonTarget.classList;
    classList.remove(this.saveButtonClass);
    classList.add(className);
    this.saveButtonClass = className;
  }

  setSaveButtonMode(mode) {
    switch (mode) {
      case "loading":
        this.saveButtonTarget.disabled = true;
        this.saveButtonTarget.innerHTML = "<div class='loading-spinner'></div>";
        this.setSaveButtonClass("btn-secondary");
        break;
      case "active":
        this.saveButtonTarget.disabled = false;
        this.saveButtonTarget.innerHTML = "Save"
        this.setSaveButtonClass("btn-primary");
        break;
    }
  }
}
