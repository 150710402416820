import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "text" ]

  connect() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.textTarget.scrollTop = this.textTarget.scrollHeight;
  }
}
